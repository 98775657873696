import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout/layout"
import Meta from "../../components/addon/meta"
import Schema from "../../components/addon/schema"
import CustomLink from '../../components/addon/Link'

const PressReleaseDetail = ({ data: { allNodePressRelease: { nodes } } }) => {
    const rawData = nodes[0]
    const metaTags = rawData?.metatags.length > 0 ? rawData?.metatags : [];
    const schema = rawData?.node?.relationships?.field_seo_schema ? rawData?.node?.relationships?.field_seo_schema : []
    const { body, title, field_banner_sub_text, field_event_date } = rawData;
    return (
        <>
            <Layout>
                <Meta
                    files={{
                        js: [],
                        css: ["/assets/css/blog.css", "/assets/css/accessibility.css"],
                    }}
                    tags={metaTags}
                />
                <Schema schema={schema} />
                <main className="innerpage">
                    {rawData?.relationships?.field_breadcrumb_press_release &&
                        <section className="breadcrumbs">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <ul>
                                            {rawData?.relationships?.field_breadcrumb_press_release?.field_breadcrumb_link?.map((el, index) => {
                                                     return  (
                                                        <li key={index}>
                                                            <CustomLink data={{link : el}}>{el.title}</CustomLink>
                                                        </li>
                                                    )
                                                })
                                            }
                                            <li><span>{rawData?.relationships?.field_breadcrumb_press_release?.field_title}</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </section>
                    }
                    <section className="section-bg section-py">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="blog_detail_head">
                                        <div className="heading_with_likes">
                                            {title && <h1>{title}</h1>}
                                            {field_banner_sub_text && <div dangerouslySetInnerHTML={{ __html: field_banner_sub_text?.processed }}></div> }
                                            {field_event_date &&
                                                <div className="blog_by">
                                                    <span className="date_time">{field_event_date}</span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    {body &&
                                        <div className="blog_content">
                                            <div className="blog_header"></div>
                                            <div className="blog_body" dangerouslySetInnerHTML={{ __html: body?.processed }}></div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </Layout>
        </>
    )
}

export const query = graphql`
query ($pressReleaseId: String) {

    allNodePressRelease(filter: {id: {eq: $pressReleaseId}}) {
  
      nodes {
        
        metatags: metatag_normalized {
  
          attributes {
  
            content
  
            name
            
  
          }
  
        }
  
        id
  
        title
  
        field_banner_sub_text {
  
          processed
  
        }
  
        field_event_date(formatString: "D MMMM, YYYY")
  
        field_quotation {
  
          processed
  
        }
  
        body {
  
          processed
  
        }
  
        field_image {
  
          alt
  
        }
  
        relationships {
  
          field_image {
  
            uri {
  
              value
  
              url
  
            }
  
          }
  
          field_breadcrumb_press_release {
  
            field_title
  
            field_breadcrumb_link {
  
              title
  
              uri
  
            }
  
          }
  
        }
  
        path {
  
          alias
  
        }
  
      }
  
    }
  
  }
`

export default PressReleaseDetail;